.image-margin-bottom {
	height: 4vw;
	background-color: white;
}
@media (min-width: 480px) {
	.image-margin-bottom {
		height: 6vw;
	}
}
@media (min-width: 768px) {
	.image-margin-bottom {
		height: 10vw;
	}
}
