.noconn-items-container {
	margin-top: 20vw;
	color: #222;
	text-align: center;
}
.noconn-items-container button {
	background-color: #222;
}

.noconn-footer {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
}
