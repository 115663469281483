nav {
	position: sticky;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	width: 100%;
	/* background-color: black; */
	top: 0;
	z-index: 3;
	direction: rtl;
}

.nav-logo-home-button {
	align-self: center;
	cursor: pointer;
}

.nav-logo-home-button a {
	outline: none;
	border: none;
	/* color: rgb(175, 175, 175); */
	text-decoration: none;
	font-weight: 400;
	display: flex;
	align-items: center;
	color: #222222;
}

.nav-logo-home-button a:hover {
	/* color: white; */
	color: #00a99d;
}
.logo {
	height: 2.5rem;
}
a:active {
	outline: none;
	border: none;
	color: #00a99d !important;
}

a:focus {
	outline: none;
	border: none;
	color: #00a99d;
}
.hamburger-icon {
	align-self: center;
	cursor: pointer;
	/* color: rgb(175, 175, 175); */
	color: #222222;
}

.hamburger-icon:hover {
	/* color: white; */
	color: #00a99d;
}

.nav-link-container {
	display: none;
}

input:focus {
	outline: none;
}

.nav-link-container > * {
	margin-right: 1.3em;
	position: relative;
}
.nav-link-container > *:hover {
	/* background-color: rgb(42, 42, 42); */
	border: 1px solid rgb(42, 42, 42);
}

.nav-link-container-mobile {
	padding: 1em;
	padding-top: 70px;
	/* border-radius: 10px; */
	position: absolute;
	display: flex;
	flex-flow: column nowrap;
	top: 0px;
	width: 100vw;
	right: 0;
	height: 30vh;
	justify-content: center;
	/* background-color: rgb(255, 255, 255); */
	background-color: #f5f7f9;
	align-items: stretch;
	z-index: 1;
	/* background-color: rgb(0, 0, 0); */
	box-shadow: 0px 0px 46px -4px rgb(176, 175, 175);
}
.nav-link-container-mobile > * {
	/* color: rgb(165, 165, 165); */
	color: black;
	margin-bottom: 5px;
	/* border: 1px solid rgb(24, 24, 24); */
	margin-right: 0px;
	text-align: center;
	text-decoration: none;
	padding: 10px 0;
	border-radius: 10px;
	background-color: #f5f7f9;
}
.nav-link-container-mobile > *:hover {
	color: #00a99d !important;
}

.cross-tab-icon {
	position: relative;
	top: 8px;
	left: 2px;
	cursor: pointer;
}

::placeholder {
	letter-spacing: 0.4px;
}

.cross-tab-icon {
	z-index: 1;
	color: red;
}

.nav-input-search {
	width: 175px;
	/* margin-top: 0.4em; */
	border: none;
	background-color: white;
	border-radius: 15px;
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	align-items: center;
}

.nav-input-search input {
	border: none;
	width: 130px;
	height: 33px;
	padding: 0.5rem 1rem;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	/* border-radius: 15px; */
}

.nav-input-search button {
	border: none;
	width: 45px;
	height: 33px;
	background-color: white;
	padding: 0.5rem 1rem;
	/* border-radius: 15px; */
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	/* margin-top: 0.1em; */
	padding-bottom: 5px;
	cursor: pointer;
}
.cart-Link {
	display: flex;
	justify-content: center;
	direction: ltr;
}
.cart-count {
	font-size: 1rem;
	font-weight: bold;
	position: relative;
	display: inline-block;
	padding: 0px 5px;
	border-radius: 30px;
	height: 75%;
	left: 1px;
	top: -3px;
	/* bottom: 15px; */
	background-color: rgb(250, 103, 103);
	color: white;
	border: none;
}
.cart {
	position: relative;
	justify-self: center;
	top: 5px;
	left: 8px;
}
.nav-logo-home-button .brand-name {
	display: none;
}
@media (min-width: 480px) {
	.nav-input-search {
		width: 250px;
	}
	.nav-input-search input {
		width: 210px;
	}
	.nav-input-search button {
		width: 40px;
	}
}
@media (min-width: 768px) {
	nav a {
		display: inline-block;
		/* color: white; */
		color: #222222;
		font-size: 1.2em;
		font-weight: 400;
		text-decoration: none;
		letter-spacing: 0.2px;
	}
	.nav-logo-home-button {
		display: inline;
	}

	.logo {
		height: 4rem;
	}
	nav {
		z-index: 3;
		align-items: center;
		/* position: sticky; */
		top: 0;
	}
	.hamburger-icon {
		display: none;
	}
	.nav-link-container {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
	}

	.nav-link-container > * {
		/* color: rgb(176, 175, 175); */
		color: #222222;
	}
	.nav-link-container > *:hover {
		/* background-color: black; */
		cursor: pointer;
		/* color: white; */
		border: none;
		color: #00a99d;
	}

	.cart-count {
		position: absolute;
		display: block;
		padding: 1px 5px;
		border-radius: 30px;
		/* height: 80%; */
		top: -6px;
		left: 17px;
		/* bottom: 15px; */
		background-color: rgb(253, 87, 87);
		color: white;
		border: none;
		/* border: 1px solid var(--primary-color); */
	}

	.cart a {
		color: white;
	}

	.cart {
		position: default;
		top: 0;
		left: 0;
		margin-top: 0.2em;
	}
	.nav-input-search {
		width: 320px;
	}
	.nav-input-search input {
		width: 280px;
	}
	.nav-input-search button {
		width: 40px;
	}
}

/* @media (min-width: 1440px) {
	nav {
		z-index: 3;
		align-items: center;
		/* position: sticky; 
		top: 0;
		height: 10rem;
	}
	nav a {
		display: inline-block;
		/* color: white;
		color: #222222;
		font-size: 2em;
		font-weight: 500;
		text-decoration: none;
		letter-spacing: 0.2px;
	}
	.nav-logo-home-button {
		display: inline;
	}

	.logo {
		height: 8rem;
	}

	.hamburger-icon {
		display: none;
	}
	.nav-link-container {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
	}

	.nav-link-container > * {
		/* color: rgb(176, 175, 175); 
		color: #222222;
	}
	.nav-link-container > *:hover {
		/* background-color: black; 
		cursor: pointer;
		/* color: white; 
		border: none;
		color: #00a99d;
	}

	.cart-count {
		position: absolute;
		display: block;
		padding: 1px 5px;
		border-radius: 30px;
		/* height: 80%; 
		top: -6px;
		left: 17px;
		/* bottom: 15px; 
		background-color: rgb(253, 87, 87);
		color: white;
		border: none;
		/* border: 1px solid var(--primary-color); 
	}

	.cart a {
		color: white;
	}

	.cart {
		position: default;
		top: 0;
		left: 0;
		margin-top: 0.2em;
	}
	.nav-input-search {
		width: 320px;
	}
	.nav-input-search input {
		width: 280px;
	}
	.nav-input-search button {
		width: 40px;
	}
} */
