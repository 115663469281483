.ordered-products-container {
	display: flex;
	flex-direction: column;
}

.ordered-product-card {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 5px;
	/* direction: rtl; */
}

.ordered-product-card:first-child {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
}

.ordered-product-card img {
	width: 75px;
	height: 75%;
	border-radius: 7px;
	background-color: rgb(241, 241, 241);
}

.product-details-container {
	padding: 15px;
	margin: 15px 15px 0 15px;
	color: grey;
}

.product-details-container h1 {
	text-align: center;
	font-size: 40px;
	color: black;
}

@media (min-width: 768px) {
	.ordered-product-card {
		justify-content: space-between;
		align-items: space-between;
	}
}
